@import '@/styles/theme';

$verticalMaskPadding: 60px;

.mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 100vh;
  padding: $verticalMaskPadding 30px 0;
  z-index: 5000; // Below react-toastify, which is 9999
}

.popup {
  display: inline-block;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: $greenBackground;
  width: 100%;
  box-shadow: 0px 0px 4px #C6E3E0;
}

.inner {
  position: relative;

  .close {
    position: absolute;
    top: 28px;
    right: 28px;
  }
}

@media screen and (min-width: $desktopWidth) {
  .mask {
    height: 100vh;
    text-align: center;
    padding-bottom: $verticalMaskPadding;
    overflow-y: auto;
  }

  .popup {
    position: static;
    border-radius: 12px;
    width: auto;
    text-align: left;
    overflow: auto; // For the border-radius
  }
}
