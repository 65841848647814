@import '@/styles/theme';

$width: 276px;

.container {
  position: relative;

  .actions {
    position: absolute;
    top: 0;
    left: -$width;
    background: white;
    width: $width;
    padding: 16px;
    box-shadow: 0px 0px 4px rgba(145, 167, 176, 0.25);

    li {
      color: $primary;
    }
  }
}
