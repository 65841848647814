.googleSignIn {
  border-radius: 20px;
  border: none;
  background: #5E95FE;
  font-weight: 600;
  color: white;
  padding: 8px;
  box-shadow: 0 0 1px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24);

  img {
    margin-right: 12px;
  }

  &:hover, &:active {
    background: #5588E9;
  }
}
