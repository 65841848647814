@import '@/styles/theme';

.container {
  padding: 16px;

  > h1 {
    font-size: 20px;
    color: $darkText;
  }
}
