.event {
  border: 1px solid white;
  border-radius: 6px;
  background: #99A8AB;
  font-weight: 500;
  width: 94%;
  height: 100%;
  padding: 6px 12px;
  margin: auto;
  overflow: hidden;
}

.event.singlePersonBusy {
  background: #ACC5CF;
}

.event.manyPeopleBusy {
  background: #91A7B0;
}

.event.selected {
  border-color: transparent;
  font-weight: 600;
  box-shadow: 0px 0px 10px #99A8AB;
}

.event.tentative {
  border-color: transparent;
  background: #03D1AD;
  box-shadow: 0px 0px 10px #03D1AD;
}
