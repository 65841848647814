@import '@/styles/theme';

$today: #03D1AD;

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; // Fixes a bug where react-big-calendar has a secret button to move forward

  .dayOfWeekAndMonth {
    .month {
      position: absolute;
      text-transform: uppercase;
      top: 6px;
      left: 16px;
      font-weight: 600;
      color: $mediumText;
    }

    .dayOfWeek {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      color: $darkText;
      margin-right: 8px;
    }
  }

  .dayOfMonth {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 20px;
    color: $darkText;
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  &.today {
    .dayOfWeek {
      color: $today;
    }

    .dayOfMonth {
      border-radius: 100%;
      background: $today;
      font-weight: bold;
      font-size: 20px;
      color: white;
      padding-bottom: 2px;
    }
  }
}
