@import '@/styles/theme';

.create {
  // border-radius: 26px;
  // font-weight: bold;
  // padding: 12px 24px;

  background: #03D1AD;
  color: white;
}
