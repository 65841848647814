.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  h1 {
    font-size: 84px;
  }

  h2 {
    font-size: 56px;
    margin-bottom: 20px;
  }

  .actions {
    a {
      text-decoration: underline;
      margin-right: 20px;
    }
  }
}
