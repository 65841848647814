.conflicts {
  border-radius: 12px;
  background: #F3F3F3;
  padding: 12px;

  h1 {
    font-weight: bold;
    color: #FB4E4E;
    margin-bottom: 12px;
  }

  ul li {
    display: flex;
    align-items: flex-start;

    label {
      margin-left: 8px;
    }

    > section {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
