.step {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  color: white;
  height: 100%;
  padding: 24px;
  padding-top: 64px;

  img {
    flex: 3;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  h1 {
    font-size: 24px;
  }

  h1, h2 {
    font-weight: bold;
  }

  .progress {
    align-self: center;
  }
}
