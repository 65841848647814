.splash {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;

  main {
    flex: 4;

    > div {
      height: 100%;
    }
  }

  .underview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 36px 24px;
  }
}
