@import '@/styles/theme';

.tertiary {
  border: none;
  background: transparent;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  color: $primary;
  width: auto;
  padding: 0;
}
