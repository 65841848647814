@import '@/styles/theme';

$delete: #FB8282;

.container {
  padding: 26px 16px;

  header {
    font-weight: bold;
    margin-bottom: 16px;

    h1 {
      font-size: 20px;
      color: $darkText;
    }
  }

  .busyPeople {
    margin: 16px 0;
  }

  .delete {
    border-color: $delete;
    color: $delete;
    margin-bottom: 12px;

    // Style the loader
    div > div {
      background: $delete;
    }
  }

  p {
    text-align: center;
    color: $lightText;
  }
}
