@import '@/styles/theme';

.primary {
  border-radius: 26px;
  border: none;
  background: $secondary;
  font-weight: 600;
  color: $mediumText;
  padding: 12px 24px;
}
