.ReactSwipeableBottomSheet--open, .ReactSwipeableBottomSheet--closed {
  overflow: visible !important;
}

.react-swipeable-view-container {
  > div:nth-child(2) {
    // Disable the overlay that prevents you from interacting with the drawer while closed
    display: none !important;
  }
}
