@import '@/styles/theme';

.container {
  padding: 26px 16px;

  header {
    font-weight: bold;
    font-size: 20px;
    color: $darkText;
    margin-bottom: 12px;
  }

  .pickers {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 24px;

    label {
      display: block;
      font-weight: 600;
      color: $darkText;
    }
  }
}
