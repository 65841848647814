@import '@/styles/theme';

.meta {
  padding: 28px;

  header {
    h1 {
      font-size: 20px;
      font-weight: bold;
      color: $darkText;
    }

    h2 {
      color: $mediumText;
    }
  }

  .name {
    margin: 20px 0;
  }
}

@media screen and (min-width: $desktopWidth) {
  .meta {
    background: #FFF7F6;

    > header {
      display: flex;
      align-items: center;
      margin-bottom: 48px;

      > span {
        color: $primary;
        margin-left: 26px;
      }
    }

    .columns {
      display: flex;
      justify-content: space-between;

      .separator {
        align-self: center;
        background: $lightText;
        width: 1px;
        height: 100px;
        margin: 0 20px 0 46px;
      }

      > section {
        flex: 1;

        > h3 {
          font-size: 18px;

          &.light {
            color: $lightText;
          }

          &.primary {
            color: $primary;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
