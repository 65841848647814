@import '@/styles/theme';

.inner {
  display: flex;
  flex-flow: column;
  height: 100%;

  .content {
    flex: 1;
  }
}
