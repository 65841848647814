
@import '@/styles/theme';

.container {
  display: flex;
  flex-flow: column;
  height: 100%;

  .finish {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex: 1;
    background: #FFF7F6;
    height: 100%;
    padding: 64px 16px;

    header {
      color: $mediumText;

      h1 {
        font-weight: bold;
        font-size: 24px;
      }
    }

    section {
      li {
        margin: 8px 0;
      }
    }
  }

  footer {
    background: white;
    padding: 30px 20px;
  }
}

.loading {
  text-align: center;
  padding-bottom: 24px;
}

@media screen and (min-width: $desktopWidth) {
  .container {
    .finish {
      padding: 64px;

      header h2 {
        margin: 12px 0;
      }
    }
  }

  .loading {
    width: 200px;
  }
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $primary;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.loader div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.loader div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.loader div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
