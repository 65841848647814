@import '@/styles/theme';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: rgba(#ACC5CF, 0.3);
  width: 100%;
  height: 100%;
  padding-top: 100px;

  .inner {
    text-align: center;
    color: $mediumText;

    h1 {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 32px;
    }
  }
}
