@import '@/styles/theme';
@import '@/components/Calendar/Calendar';

$drawer-closed-height: 186px;
$overview-height: 80px;
$headers-height: 40px;

.content {
  display: flex;
  flex-flow: column;
  background: $greenBackground;
  height: calc(100% - #{$drawer-closed-height});
  overflow: hidden;

  .inner {
    display: flex;
    flex: 1;
    overflow-y: scroll; // 6/20/20 - enables scrolling on the calendar on mobile

    .calendarContainer {
      display: flex;
      flex-flow: column;
      flex: 1;
    }
  }

  .calendar {
    flex: 1;
    overflow-y: scroll;
    z-index: 1; // Makes it so you can scroll this
  }

  .overview, .calendarHeaders {
    background: white;
  }

  .calendarHeaders {
    position: relative;
    display: flex;
    padding-bottom: 8px;
    padding-left: $time-gutter-width;
    box-shadow: 0px 1px 2px rgba(107, 107, 107, 0.35);

    .calendarHeader {
      flex: 1;
    }
  }
}

@media screen and (min-width: $desktopWidth) {
  .content {
    background: white;
    height: 100%;
    overflow: visible;

    .inner {
      height: calc(100% - #{$overview-height});

      .drawer {
        width: 350px;
        padding: 26px;
      }

      .calendarContainer {
        display: block;
        position: relative;
        padding: 26px 0 0 26px;

        .calendarHeaders {
          box-shadow: none;
        }

        .calendar {
          height: calc(100% - #{$headers-height});

          &.empty {
            overflow: hidden;
          }
        }
      }
    }
  }
}
