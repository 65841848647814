$primary: #6F9EFB;
$secondary: #26F8D2;

$greenBackground: #DFFFFB;

$lightText: #91A7B0;
$mediumText: #576575;
$darkText: #303444;

$desktopWidth: 1000px;
