@import '@/styles/theme';

.textbox {
  border: 1px solid #91A7B0;
  border-radius: 26px;
  background: white;
  color: $darkText;
  width: 100%;
  padding: 14px 22px;

  &::placeholder {
    color: $lightText;
  }
}
