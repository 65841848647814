@import '@/styles/theme';

* {
  box-sizing: border-box;
}

body {
  font: 500 16px 'acumin-pro', Helvetica, sans-serif;
  color: $darkText;
  margin: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font: inherit;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

button, input, select {
  font: inherit;
}

img, svg {
  display: block;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
