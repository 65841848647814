@import '@/styles/theme';

.container {
  display: flex;
  align-items: center;

  .controls {
    display: flex;

    .header {
      margin-right: 36px;

      h2 {
        font-size: 24px;
      }
    }

    .navigation {
      display: flex;
      justify-content: center;

      .button {
        text-transform: uppercase;
        color: $lightText;

        &.today {
          border-color: $lightText;
          padding: 0 11px;
        }

        img {
          height: 28px;
          max-height: none;
          margin-right: 0;
        }
      }
    }
  }

  .feedback {
    display: block;
    flex: 1;
    font-weight: bold;
    font-style: normal;
    padding-left: 14px;
  }
}
