@import '@/styles/theme';

.indicator {
  display: flex;
  align-items: center;
  position: absolute;
  top: 80px;
  left: 20px;
  border-radius: 24px;
  font-weight: bold;
  color: white;
  padding: 12px 20px;
  box-shadow: 0px 0px 10px #4E83EA;
  z-index: 2;

  > span {
    margin-right: 12px;
  }
}

@media screen and (min-width: $desktopWidth) {
  .indicator {
    top: 80px;
    left: 56px;
  }
}
