@import '@/styles/theme';

.top, .belowOverview, .aboveDrawer {
  padding: 16px !important;
}

.topBody, .belowOverviewBody, .aboveDrawerBody {
  padding: 0 !important;
}

.belowOverview {
  margin-top: 48px;
}

.aboveDrawer {
  bottom: 200px !important;
}

@media screen and (min-width: $desktopWidth) {
  $toastWidth: 500px;

  .top, .belowOverview, .aboveDrawer, .rightOfLeftBar {
    width: $toastWidth !important;
  }

  .belowOverview, .top {
    left: calc(50% - #{$toastWidth / 2}) !important;
  }

  .top {
    top: 16px !important;
  }

  .belowOverview {
    top: 130px !important;
    margin-top: 0;
  }

  .rightOfLeftBar {
    top: 500px !important;
    left: 350px !important;
  }
}
