@import '@/styles/theme';

.secondary {
  border-radius: 26px;
  border: 2px solid #03D1AD;
  background: transparent;
  font-weight: bold;
  color: #03D1AD;
  padding: 12px 24px;
}
