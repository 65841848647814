@import '@/styles/theme';

.drawer {
  border: none;
  border-radius: 16px;
  background: linear-gradient(79.61deg, #1ED79F 8.34%, #0ED9CD 94.95%);
  font-weight: bold;
  color: white;
  width: auto !important;
  padding: 4px 16px;
}
