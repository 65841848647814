@import '@/styles/theme';

.toast {
  border-radius: 8px;
  background: #FAFEF9;
  color: $mediumText;
  padding: 16px 24px;
  box-shadow: 0px 0px 4px rgba(145, 167, 176, 0.25);

  h1 {
    font-weight: bold;
  }

  p {

  }
}
