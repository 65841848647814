@import '@/styles/theme';

$calendar-border: transparent;
$cell-border: #DADADA;

$event-bg: #78A1EB;
$event-padding: 5px 8px;
$event-border: none;
$event-outline: none;
$event-border-radius: 6px;

$today-highlight-bg: transparent;

@import 'react-big-calendar/lib/sass/styles';

$half_hour_partitions: 1; // The number of partitions to divide half-hour cells into
$cell-height: (50px / $half_hour_partitions);
$time-gutter-width: 48px;
$scroll-button-vertical-offset: 96px;
$scroll-button-horizontal-offset: 18px;

$time-height: 40vh;

.rbc-calendar {
  position: relative;
  overflow: hidden;
}

.rbc-time-content {
  position: relative;
  padding-bottom: 20px;
  overflow-y: hidden; // Enabled 01-24-20 to get rid of double scrollbars on some weeks, disabled 01-30-20 to put scrollbar on times back, enabled 01-17-20 to use Event overflow
}

.rbc-time-view {
  background: transparent;
  padding-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .rbc-time-content::after {
    display: none;
  }

  .rbc-time-view {
    padding-bottom: 0;
  }
}

.rbc-time-header {
  display: none; // No all day events for now
  position: relative;
}

.rbc-time-header-cell {
  // min-height: 55px !important;
}

.rbc-time-header-content {
  &, .rbc-day-bg {
    border-left: none;
  }
}

.rbc-day-slot:nth-child(2) {
  .rbc-timeslot-group, .rbc-events-container {
    border-left: none;
  }

  .rbc-time-slot {
    // background: linear-gradient(270deg, #FFFFFF 0%, #FAFAFA 100%); // TODO: figure out the proper gradient
  }
}

.rbc-day-slot .rbc-events-container {
  margin: 0;
}

.rbc-day-slot .rbc-event {
  border: none;
  background: transparent; // Default color, should be overriden
  padding: 0;
  overflow: visible;
}

.rbc-slot-selection {
  border: 1px solid transparent;
  border-radius: 6px !important;
  background: #03D1AD;
  font-weight: 500 !important;
  font-size: 16px !important;
  width: calc(100% - 16px);
  height: 50px;
  padding: 3px 12px !important;
  margin-left: 8px;
}

.ReactCalendar-addingManually .rbc-slot-selection {
  background: #AB7BD0;
  font-size: 12px;
}

.rbc-header {
  border-bottom: none;
}

.rbc-header + .rbc-header {
  border-left: none;
}

.rbc-day-slot .rbc-time-slot {
  border-top: none;
  background: none;
}

.rbc-timeslot-group {
  min-height: $cell-height;

  &:not(:nth-child(#{$half_hour_partitions}n)) {
    border-bottom: none;
  }
}

.rbc-time-gutter {
  text-align: right;
  font-size: 14px;
  color: $lightText;
  width: $time-gutter-width;
  padding-right: 10px;

  .rbc-timeslot-group {
    position: relative;
    top: -12px;
    border-bottom: none;
    min-height: #{$cell_height * $half_hour_partitions};

    &:nth-child(-n + #{$half_hour_partitions}) {
      visibility: hidden;
    }

    &:not(:nth-child(#{$half_hour_partitions}n)) {
      display: none;
    }
  }
}

.rbc-event-label {
  // Hide the start/end time on the events
  display: none;
}

.event-disabled {
  color: transparent;
}

.rbc-current-time-indicator {
  display: none;
}

.rbc-allday-cell {
  // display: none;
}
