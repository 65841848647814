@import '@/styles/theme';

.container {
  display: flex;
  align-items: center;

  .label {
    flex: 1;
    text-align: left;
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .check {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 2px solid $lightText;
    width: 18px;
    height: 18px;
    margin-right: 14px;
    padding: 3px;

    .icon {
      * {
        fill: white;
        stroke: white;
      }
    }
  }

  &.checked {
    .label {
      font-weight: bold;
    }

    .check {
      border-color: transparent;
    }
  }

  &.blinking {
    animation: blink 1s infinite;
  }
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
