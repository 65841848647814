@import '@/styles/theme';

.container {
  .swiper {
    border-radius: 4px;
    background: $secondary;
    width: 20%;
    height: 6px;
    margin: 6px auto 12px;
    box-shadow: 0px 0px 12px $secondary;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: $secondary;
    padding: 12px 16px;

    > div {
      display: flex;
      align-items: center;

      h1 {
        font-weight: bold;
        font-size: 16px;
        color: $mediumText;
        padding-left: 12px;
      }
    }
  }
}
