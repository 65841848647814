@import '@/styles/theme';

.details {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $greenBackground;
  padding: 26px;
  z-index: 10;
}

.container {
  header {
    display: flex;
    justify-content: space-between;

    button {
      border: none;
      background: none;
      font-weight: bold;
    }
  }

  .person {
    display: flex;
    align-items: center;
    margin: 40px 0;

    > div:first-child {
      margin-right: 12px;
    }

    h1 {
      font-size: 20px;
    }

    h2 {
      color: $lightText;
    }
  }

  ul li {
    font-size: 18px;
    margin: 12px 0;

    .description {
      display: block;
      color: $lightText;
    }
  }
}

.darkText {
  color: $darkText;

  // Style the loader
  div > div {
    background: $darkText;
  }
}

.signIn {
  font-weight: bold;
  color: $lightText;
  width: 100px;
  padding: 14px 24px;
}

@media screen and (min-width: $desktopWidth) {
  .container {
    position: fixed;
    top: 100px;
    right: 50px;
    border-radius: 8px;
    background: #FAFEF9;
    padding: 30px;
    box-shadow: 0px 0px 4px rgba(145, 167, 176, 0.25);
    z-index: 2; // Above the calendar

    .person {
      margin: 0 0 20px 0;
    }
  }
}
