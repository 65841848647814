@import '@/styles/theme';

.drawer {
  display: flex;
  flex-flow: column;
  background: #FFF7F7;
  padding: 20px 14px;
  box-shadow: 0px 0px 4px #C6E3E0;

  > section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    > h1 {
      font-weight: bold;
      font-size: 14px;
      color: $lightText;
      margin-bottom: 12px;
    }
  }
}

@media screen and (min-width: $desktopWidth) {
  .drawer {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: none;
  }
}
