@import '@/styles/theme';

.logo {
  display: flex;
  align-items: center;

  img {
    display: block;
    width: 30px;
    margin-right: 12px;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
    color: $primary;
    margin-right: 8px;
  }

  .phase {
    font-size: 14px;
    color: $primary;
    margin-top: 6px;
  }
}
