@import '@/styles/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;

  .nameAndToolbar {
    display: flex;
    align-items: center;

    .name {
      font-weight: 600;
      color: $darkText;
    }
  }
}

@media screen and (min-width: $desktopWidth) {
  .container {
    justify-content: normal;
    background: $greenBackground;
    box-shadow: 0px 1px 2px rgba(107, 107, 107, 0.15);

    .nameAndToolbar {
      position: relative;
      left: 202px;

      .name {
        font-size: 20px;
        margin-right: 36px;
      }
    }

    .me {
      margin-left: auto;
    }
  }
}
