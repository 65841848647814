@import '@/styles/theme';

.confirmed {
  padding: 26px 16px;

  header {
    margin-bottom: 20px;

    h1 {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 8px;
    }

    h2 {
      color: $mediumText;
    }
  }

  .details {
    border-radius: 12px;
    background: #F3F3F3;
    padding: 12px;

    > section {
      display: flex;
      align-items: center;

      .time {
        font-size: 24px;
      }

      .amPM {
        font-size: 14px;
        margin-top: 4px;
        margin-left: 6px;
      }

      hr {
        border: none;
        background: $darkText;
        width: 12px;
        height: 2px;
        margin: 0 12px;
      }
    }

    .name {
      font-weight: bold;
      margin: 8px 0;
    }

    .date {
      color: $mediumText;
    }
  }

  .add {
    border-color: $primary;
    color: $primary;
    margin: 20px 0;
  }

  .cancel {
    font-weight: bold;
    color: $lightText;
    margin: auto;
  }
}
