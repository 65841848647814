@import '@/styles/theme';

.drawer {
  padding: 28px;

  header {
    margin-bottom: 10px;

    h1 {
      font-size: 20px;
      font-weight: bold;
      color: $darkText;
    }

    h2 {
      color: $mediumText;
    }
  }

  section ul li {
    margin: 10px 0;
  }
}
