@import '@/styles/theme';

.howItWorks {
  background: #FFF7F6;
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow-y: auto;

  .inner {
    width: 100%;
    height: 100%;

    > header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    nav {
      padding: 24px 0;

      > ol {
        display: flex;
        justify-content: space-evenly;

        > li {
          display: flex;
          align-items: center;
          color: $lightText;

          > div:first-child {
            // Step number
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            font-size: 16px;
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }

          > div:last-child {
            // Step name
            font-size: 16px;
          }

          &.selected {
            font-weight: bold;
            color: #03D1AD;

            > div:first-child {
              background: #03D1AD;
              color: white;
            }
          }
        }
      }
    }

    .main {
      .image {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        margin-bottom: 30px;

        > img {
          width: 80%;
        }

        > button {
          border: none;
          background: none;

          > svg {

          }

          &.hidden {
            visibility: hidden;
          }
        }
      }

      .description {
        > h1 {
          font-weight: bold;
          font-size: 20px;
          color: $darkText;
          margin-bottom: 12px;
        }

        > p, > section > p {
          font-weight: normal;
          color: $mediumText;
          line-height: 28px;
        }

        .create {
          padding-top: 20px;
          margin: auto;
        }

        .time {
          display: flex;
          padding: 12px 0;

          > div:first-child {
            // Color square
            border-radius: 2px;
            width: 16px;
            height: 16px;
            margin-top: 5px; // Align with the text header
            margin-right: 12px;
          }

          > div:last-child {
            // Text
            flex: 1;

            > h2 {
              font-weight: bold;
              margin-bottom: 12px;
            }

            > p {
              font-weight: normal;
              color: $lightText;
              line-height: 24px;
            }
          }

          &.ownTime {
            > div:first-child {
              background: $primary;
            }

            > div:last-child > h2 {
              color: $primary;
            }
          }

          &.singleTime {
            > div:first-child {
              background: #ACC5CF;
            }

            > div:last-child > h2 {
              color: #ACC5CF;
            }
          }

          &.multiTime {
            > div:first-child {
              background: #91A7B0;
            }

            > div:last-child > h2 {
              color: #91A7B0;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $desktopWidth) {
  .howItWorks {
    position: relative;
    border-radius: 12px;
    width: 90%;
    height: auto;
    overflow-y: hidden;

    .inner {
      width: 60%;
      margin: auto;

      nav > ol {
        justify-content: space-between;

        > li {
          margin: 0 12px;
        }
      }

      .close {
        position: absolute;
        top: 50px;
        right: 50px;
      }

      .main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .image {
          margin-right: 10%;

          > img {
            position: relative;
            top: 140px;
            height: 500px;
          }

          > button {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            background: #EEEDEF;
            width: 64px;
            height: 184px;

            &.left {
              left: -15%;
            }

            &.right {
              right: -15%;
            }

            > svg * {
              stroke: #303444;
            }
          }
        }

        .description {
          flex: 1;

          .create {
            width: 310px;
          }
        }
      }
    }
  }
}
