@import '@/styles/theme';

.landing {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  background-repeat: no-repeat;
  background-position-y: top;
  background-size: cover auto;
  height: 100%;

  .mobileBackground {
    width: 100%;
  }

  .logo {
    margin-bottom: 24px;
  }

  .logo {
    margin-bottom: 24px;
  }

  main, footer {
    padding: 24px;
  }

  main {
    header {
      margin-bottom: 32px;

      h1 {
        font-weight: bold;
        font-size: 20px;
        color: $darkText;
        margin-bottom: 20px;
      }

      h2 {
        font-size: 14px;
        color: $mediumText;
      }
    }
  }

  .actions {
    margin-bottom: 32px;

    .create {
      background: #03D1AD;
      color: white;
    }
  }

  .openTutorial {
    padding: 16px 0;

    > button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      border-radius: 12px;
      background-color: #FFF8F7;
      background-repeat: no-repeat;
      background-position: bottom right;
      font-weight: bold;
      color: $primary;
      padding: 0 20px;
      box-shadow: 0px 0px 14px rgba(198, 186, 186, 0.25), 0px 4px 12px rgba(202, 202, 202, 0.25);

      > img {
        width: 128px;
        margin-right: 30px;
      }

      > div > svg {
        position: relative;
        top: 2px;
        display: inline;
      }
    }
  }

  footer, footer .contact {
    color: $lightText;
  }

  footer .contact {
    text-decoration: underline;
    padding-left: 4px;
  }
}

@media screen and (min-width: $desktopWidth) {
  .landing {
    background-position-x: right;
    background-position-y: initial;
    padding: 80px 0 80px 10%;

    main {
      width: 600px;

      header {
        h1 {
          font-size: 32px;
        }

        h2 {
          font-size: 18px;
        }
      }
    }

    .logo {
      padding: 24px;
      margin-bottom: 0;
    }

    .actions {
      width: 310px;
    }
  }
}
