@import '@/styles/theme';

.UserAvatar {
  display: block;
}

.UserAvatar--inner {
	text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserAvatar--dark {
  color: white;
}

.UserAvatar--light {
  color: gray;
}
