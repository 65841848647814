.outlookSignIn {
  border-radius: 20px;
  border: none;
  background: #0C6BCD;
  font-weight: 600;
  color: white;
  padding: 8px;

  img {
    margin-right: 12px;
  }

  &:hover, &:active {
    background: #0A62BD;
  }
}
