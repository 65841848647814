.container {
  .dot {
    display: inline-block;
    border-radius: 100%;
    background: rgba(white, 0.4);
    width: 6px;
    height: 6px;

    &.current {
      background: white;
    }

    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}
