@import '@/styles/theme';

.drawer {
  h1 {
    font-weight: bold;
    color: $mediumText;
    margin-bottom: 12px;
  }

  .dragToSchedule {
    color: #03D1AD;
  }
}
