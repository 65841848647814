@import '@/styles/theme';

.toast {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: $secondary;
  color: $darkText;
  padding: 16px 24px;

  img {
    margin-right: 12px;
  }

  > p {
    flex: 1;
    margin-left: 14px;
  }
}
