@import '@/styles/theme';

.drawer {
  > section {
    display: flex;
    justify-content: space-between;

    > header {
      color: $lightText;
      margin: 20px 0;

      h1 {
        font-weight: bold;
      }
    }
  }


  .deleteCalendars {
    margin: 6px 0;
  }

  .addManually, .deleteCalendars {
    color: $lightText;
  }

  .addingManually {
    font-weight: bold;
    color: $mediumText;
  }
}

@media screen and (min-width: $desktopWidth) {
  .drawer > section > header {
    margin: 0;
    margin-bottom: 20px;
  }
}
