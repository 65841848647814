.participants {
  display: flex;

  > div:not(:last-child) {
    margin-right: 20px;
  }

  .add {
    span:first-child {
      max-height: none;
    }
  }
}
